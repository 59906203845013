* {
  margin: 0;
  padding: 0;
  font-family: 'Roboto', 'Open Sans', 'Helvetica Neue', sans-serif;
}

:root {
  --primary: #5C4DCE;
  --secondary: rgba(92, 77, 206, 0.2);
}

html {
  scroll-behavior: smooth;
  font-size: 16px;
}

body {
  width: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  scroll-behavior: smooth;
}

#root {
  width: 100vw;
  height: 100%;
  overflow: hidden;
}

p {
  font-size: 14px;
}

h1 {
  font-size: 38px;
}

h2 {
  font-size: 28px;
}

h3 {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 28px;
}

@media (min-width: 500px) {
  h1 {
    font-size: 40px;
  }

  h2 {
    font-size: 32px;
  }

  h3 {
    font-size: 20px;
    line-height: 30px;
  }
}

@media screen and (min-width: 800px){
  p {
    font-size: 18px;
  }
  
  h1 {
    font-size: 60px;
  }

  h2 {
    font-size: 48px;
  }

  h3 {
    font-size: 32px;
  }
}

@media screen and (min-width: 1500px){
  p {
    font-size: 22px;
  }
  
  h1 {
    font-size: 80px;
  }

  h2 {
    font-size: 54px;
  }
}